.panel {
  text-align: left;
  padding: 50px;
  margin: 0 auto 80px;
  max-width: 800px;
  border: none;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.075);
  background-color: white;
  &-body {
    padding: 15px;
  }
  &-title {
    margin-top: 0;
    font-size: 26px;
    margin-bottom: 20px;
    color: var(--falcon-primary);
  }

  .content-wrapper {
    font-size: 14px;
    color: #343434;
    .title {
      font-size: 16px;
      font-weight: 600;
      margin-top: 30px;
      margin-bottom: 10px;
      display: block;
    }
    .paragraph {
      margin-bottom: 10px;
      display: block;
      .line {
        display: block;
      }
    }
    #company-attributes {
      .line {
        margin-top: 2px;
        display: block;
        .attribute-name {
          color: #999;
          margin-right: 10px;
        }
      }
    }
  }
}
