.banner-layout {
  &-container {
    margin: auto;
    padding: 0 8px;
    max-width: 1600px;
  }

  &-banner-image {
    position: relative;
    height: 250px;
  }

  &-children {
    padding: 0 2em 4em 2em;
    position: relative;
    z-index: 98;
    min-height: 750px;
  }
}
