.contact-quick-view {
  display: flex;
  padding: 1rem;
  background: white;
  margin-bottom: 0.5em;
  transition: 0.1s all ease;
  border: 1px solid #ccc;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 5px 0 #ccc;
  }
  h5 {
    font-size: 1.1em;
    color: $gray-900;
  }

  &-modal {
    .modal-dialog {
      width: 600px;
    }
  }
}
