$table-LG: 50px minmax(150px, 1fr) minmax(90px, 1fr) minmax(80px, 1fr)
  minmax(200px, 230px) 50px;

$table-LG-food: 50px minmax(100px, 1fr) minmax(270px, 2fr) minmax(100px, 1fr)
  50px;
$table-LG-formulations: 50px minmax(100px, 1.5fr) minmax(270px, 1.5fr)
  minmax(100px, 1fr) 50px;
$table-MOBILE: 50px minmax(150px, 1fr) 1fr 60px;
$table-MD: 40px minmax(120px, 1fr) minmax(70px, 1fr) minmax(90px, 1fr) 40px;
$table-SM: 40px minmax(120px, 1fr) minmax(140px, 1fr) 45px;
$table-XXS: 40px minmax(100px, 1fr) minmax(100px, 1fr) 0px;

$table-LG-columns-events: 50px minmax(120px, 120px) minmax(170px, 170px)
  minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 150px) minmax(170px, 1fr)
  60px;
$table-MD-columns-events: 50px minmax(90px, 120px) minmax(130px, 170px)
  minmax(140px, 1fr) minmax(150px, 1fr) minmax(150px, 150px) 60px;

$table-SM-columns-events: 50px minmax(90px, 120px) minmax(130px, 170px)
  minmax(150px, 1fr) minmax(150px, 150px) 60px;

$table-MOBILE-columns-events: 50px minmax(130px, 170px) minmax(150px, 1fr) 60px;

.type-SERVICES {
  .table-action-btn-wrapper {
    justify-self: end;
    @media (width<1050px) {
      margin-right: 20px;
    }
  }
}
.btn.table-action-btn {
  padding: 1px 0px 0px 8px !important;
  height: 31px;
  width: 31px;
}

.search-results-table-header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;
  background-color: white;
  display: grid;
  grid-template-columns: $table-LG;

  &-food {
    grid-template-columns: $table-LG-food;
  }

  gap: 16px;
  // border: 1px solid #ddd;
  border-bottom: 1px solid var(--falcon-primary) !important;
  padding: 0.5em 0;
  &-events {
    &-LG {
      grid-template-columns: $table-LG-columns-events;
    }
    &-MD {
      grid-template-columns: $table-MD-columns-events !important;
    }
    &-SM {
      grid-template-columns: $table-SM-columns-events !important;
    }
    &-MOBILE {
      grid-template-columns: $table-MOBILE-columns-events !important;
    }
  }
  p {
    margin: 0;
  }
  &-XXS {
    grid-template-columns: $table-XXS;
  }

  &-MOBILE {
    grid-template-columns: $table-MOBILE;
  }
  &-SM {
    grid-template-columns: $table-SM;
  }
  &-MD {
    grid-template-columns: $table-MD;
  }
  &-LG {
    .type-FORMULATIONS & {
      grid-template-columns: $table-LG-formulations;
    }
  }
}

.list-group-item {
  padding: 0 0;
}
.search-result-list-item-row {
  display: grid !important;
  grid-template-columns: $table-LG;

  &-food {
    grid-template-columns: $table-LG-food;
  }

  gap: 16px !important;
  padding: 0 !important;
  align-items: center;
  &-events {
    &-LG {
      grid-template-columns: $table-LG-columns-events;
    }
    &-MD {
      grid-template-columns: $table-MD-columns-events !important;
    }
    &-SM {
      grid-template-columns: $table-SM-columns-events !important;
    }
    &-MOBILE {
      grid-template-columns: $table-MOBILE-columns-events !important;
    }
  }

  &-XXS {
    grid-template-columns: $table-XXS;
  }
  &-MOBILE {
    grid-template-columns: $table-MOBILE;
  }
  &-SM {
    grid-template-columns: $table-SM;
  }
  &-MD {
    grid-template-columns: $table-MD;
  }

  &-LG {
    .type-FORMULATIONS & {
      grid-template-columns: $table-LG-formulations;
    }
  }
}
.search-result-list-wrapper-icons {
  align-items: center;
}

.search-result-list-info {
  @extend .truncate;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
