.closeDetail {
  position: absolute;
  right: 1.5em;
  top: 1em;
  svg {
    transform: scale(1.3);
  }
}
.product-detail {
  display: flex;
  flex-direction: row;
  @media (width<768px) {
    flex-direction: column;
  }
}
.product-detail-title {
  font-size: 2em;
}

.product-detail-label {
  // Magic color value here to fix the contrast ratio.
  // Should be fixed as soon as we have a design system with colors which work for good accessibility.
  color: #595959;
  font-size: 16px;
  flex: 0 0 190px;
  margin: 0;
  @media (width<768px) {
    flex: 0 0 0;
    font-size: 16px;
    margin-bottom: 20px;
  }
}
.product-detail-subtitles {
  @extend %company-page-header-font;
}

.product-detail-subtitles-category {
  @extend %company-page-header-font;
  font-size: 20px;
}

.product-detail-texts {
  @extend %company-page-font;
}

.bubble {
  font-size: 14px;
  // Magic color value here to fix the contrast ratio.
  // Should be fixed as soon as we have a design system with colors which work for good accessibility.
  color: #343434;
  margin-right: 4px;
  background-color: #f4f4f4;
  // border-radius: 1.6em;
  padding: 5px 16px;
  margin-bottom: 5px;
  line-height: 16px;
  display: inline-block;
}
