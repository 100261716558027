.events {
  &-details {
    &-img {
      background-size: cover;
      background-position: center;
      overflow: hidden;
      width: 280px;
      height: 200px;
    }
  }
  &-submit-button {
    align-self: flex-end;
    justify-self: flex-end;
  }
  &-reset-button {
    margin-left: 15px;
  }
  &-buttons-row {
    text-align: right;
  }
  &-grid {
    &-item {
      max-width: 280px !important;
      padding: 0px 0px 0px 0px !important;
      margin: 10px;
      &-image {
        background-size: cover;
        background-position: center;
        overflow: hidden;
        width: 280px;
        height: 200px;
      }
      &-badgeBlock {
        margin-top: -2em;
        flex-direction: column;
      }
      &-description {
        height: 72px;
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
      }
    }
  }
  &-company-editor-block {
    padding: 0 1.8rem;
  }
  &-modal {
    &-close-button {
      top: 0px;
      right: 1rem;
      position: absolute;
      border-radius: 2px;
      opacity: 1 !important;
      background: 50% center / 1em no-repeat white;
      background-image: none !important;
      display: flex;
      justify-content: center;
      padding: 0.4rem;
      margin-top: 1.8rem;
    }
    &-header-image {
      height: 250px;
      margin: auto;
    }
    &-action-button {
      position: absolute;
      top: 200px;
      right: 1rem;
    }
    &-image-header {
      display: flex;
      max-height: 250px;
      overflow: hidden;
    }
  }
}

.table-container {
  &-actions {
    justify-content: left;
    padding-bottom: 4px;
    display: flex;
    flex-direction: row;
  }
  &-search {
    display: flex;
    width: 240px;
  }
  &-filter-type {
    margin-left: 15px;
    width: 15%;
  }
  &-filter-status {
    margin-left: 15px;
    width: 15%;
  }
  &-createButton {
    z-index: 10;
    align-self: flex-end;
    width: fit-content !important;
  }
  &-type {
    font-size: 14px;
  }
}

.form {
  .form-group {
    margin-top: 8px !important;
    // margin-bottom: 15px;
    &-first {
      margin-top: 0px;
    }
  }
}

.events-form-switch {
  :hover {
    cursor: pointer;
  }
}

.events-edit-button {
  margin-bottom: 6px;
  max-width: fit-content !important;
}

.table-toggle-hover {
  cursor: pointer;
}

.events-name-group {
  width: 55% !important;
}

.events-from-to-row {
  margin-top: -10px !important;
}

.events-no-data-card {
  text-align: -webkit-center;
}

.events-new-exhibitor {
  align-self: flex-end;
  .form-check {
    margin-bottom: 0px;
    padding-left: 2rem;
  }
}

.events-card-container {
  cursor: pointer;
}

.spinner-row {
  padding: 6px;
  justify-content: center;
  .spinner-grow {
    animation-duration: 1.75s;
    margin: 0px 6px;
    width: 1rem;
    height: 1rem;
  }
}

.secondary-header {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.success {
  color: #479273;
}
