.leads-details {
  h3 {
    font-size: 24px;
  }
}

.leads-detail-panel {
  padding-left: 0;
  padding-right: 0;
  &--row {
    row-gap: 1rem;
  }
  display: flex;
  flex-direction: column;
  &--tags {
    gap: 0.5rem;
    &--tag {
      width: fit-content !important;
      color: grey !important;
      font-size: 0.75rem !important;
      &.badge {
        border-radius: 5% !important;
        padding: 0.25rem 0.5rem !important;
      }
    }
  }

  .detail-item-title {
    font-size: 12px;
  }

  .detail-item-value {
    font-size: 14px;
  }

  .react-select__control {
    min-height: 36px !important;
  }

  .events {
    .MuiAccordion-rounded {
      border: 1px solid #eaeaea;
    }

    .accordion-body {
      padding: 14.4px;
      border-top: 1px solid #eaeaea;
    }

    .entry-item {
      &--header {
        button:first-of-type {
          padding: 0.4rem;
        }
      }
      width: 100%;
      display: block;
      padding: 0.5em;
      &:not(:first-child) {
        border-top: 1px solid #eef0f3;
      }
      &-title {
        font-size: 14px;
        font-weight: 700;
        color: $gray-900;
      }
      &-value {
        font-size: 12px;
        color: $gray-600;
        font-weight: 400;
        margin-bottom: 10px;
      }
    }
  }
}

.leads-details-grid {
  display: grid;
  gap: 8px;
  grid-template-columns: 8fr 6fr;
  padding: 0;
}

.card-widget-scrollable-area {
  max-height: 300px;
  overflow-y: scroll;
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #cfd0d3;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #b4b5b8;
  }
}

.widget-label {
  font-size: 12px;
}
.widget-text {
  color: $gray-1100;
}
.entries-block {
  background-color: white;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 1em;
}
.entry-date {
  color: $gray-1100;
  font-size: 14px;
}

.entry-item {
  display: block;
  padding: 0.5em;
  &:not(:first-child) {
    border-top: 1px solid #eef0f3;
  }
  &-title {
    color: $gray-1100;
  }
  &-value {
    color: $gray-600;
  }
}
