.explainMeIcon {
  margin-left: 0.6em;
  text-align: center;
  width: 16px;
  height: 16px;
  background-color: #dddddd;
  border-radius: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  svg {
    transform: scale(0.7);
  }
}
.sticky_explainMe {
  display: inline-block;
  &-tooltip {
    .tooltip-inner {
      background-color: $white;
      border: 1px solid #c2c2c2;
      max-width: 300px;
      padding: 12px;
    }
    .tooltip-arrow {
      visibility: hidden;
    }
  }
  &-info {
    @extend %company-page-font;
    font-size: 14px;
    font-weight: 400;
    background-color: $white;
  }
  &-text {
    @extend %company-page-font;
    font-size: 14px;
    color: var(--falcon-primary);
    &:hover {
      font-weight: bolder;
    }
  }
}
