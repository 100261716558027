

/*-----------------------------------------------
|   Settings Panel
-----------------------------------------------*/

.settings-panel-header {
  min-height: $top-nav-height;
  position: relative;
  display: flex;
  align-items: flex-start;
  border: none;
  border-radius: 0;
  padding: 0.5rem 1.25rem;
  // background-image: linear-gradient(-45deg, #4695ff, #1970e2);
  overflow: hidden;
  &:before,
  &:after {
    
    border-radius: 50%;
    height: 12.5rem;
    width: 12.5rem;
   }
  &:before {
    left: 5.125rem;
    top: 1.188rem;
  }
  &:after {
    right: -6.25rem;
    top: -7.938rem;
  }

  .btn-close {
    z-index: 1;
    position: absolute;
    top: 6px;
    right: 7px;
    color: white;
    opacity: 0.75;
    padding-top: 0.75rem;
    margin-top: 0;
    margin-right: map_get($spacers, 1);
    outline: 0;
    @include hover-focus {
      &:not(:disabled),
      &:not(.disabled) {
        opacity: 1;
      }
    }
  }
}

.btn-navbar-vertical,
.btn-navbar-top {
  border-color: $gray-400;
  &.active {
    border-color: $primary;
    background-color: $primary;
    color: #fff;
  }
}
.btn-theme-default,
.btn-theme-dark {
  width: 50%;
  padding: 0;
  &.focus,
  &:focus {
    box-shadow: none;
  }
  .custom-control-label {
    cursor: pointer;
  }

  .hover-overlay {
    border: 3px solid transparent;
    position: relative;
    overflow: hidden;
    border-radius: $border-radius-lg;
    display: block;
    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      z-index: 1;
      transition: $transition-base;
    }
  }
  @include hover-focus {
    .hover-overlay {
      &:after {
        background: rgba($black, 0.2);
      }
    }
  }
  &.active {
    box-shadow: none;
    .hover-overlay {
      border: 3px solid $success;
    }
  }

  .form-check {
    text-align: start;

    .form-check-input {
      &:focus {
        box-shadow: none;
      }
    }
    .form-check-input:checked {
      background-color: var(--#{$variable-prefix}success);
      border-color: var(--#{$variable-prefix}success);
    }
  }
}

.btn-theme-default {
  margin-right: map_get($spacers, 2);
}
.btn-theme-dark {
  margin-left: map_get($spacers, 2);
}

.btn-group-navbar-style {
  .btn {
    border-radius: $border-radius !important;
    label:first-child {
      background-color: $gray-100;
      border: 3px solid $gray-300;
      border-radius: $border-radius-lg;
    }
    &.active {
      label:first-child {
        border-color: $success;
      }
    }
    &.focus,
    &:focus {
      box-shadow: none !important;
    }
    &.disabled label {
      cursor: not-allowed;
    }
  }
}

.setting-toggle {
  position: fixed;
  top: 50%;
  right: 0;
  text-decoration: none;
  z-index: 1016;
  transform: rotate(-90deg) translate3d(-25px, 39px, 0);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-top: -83px;
  box-shadow: var(--#{$variable-prefix}setting-toggle-shadow);
  cursor: pointer;

  
  @include media-breakpoint-down(sm) {
    transform: rotate(-90deg);
    outline: none;
    .bg-soft-primary {
      background-color: transparent !important;
    }
    small {
      display: none;
    }
  }

  &:hover {
    text-decoration: none !important;
  }
}
