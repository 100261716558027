$cell-grey: #eff3f5;
$cell-light-grey: #eef3f630;
$cell-yellow: rgb(248, 218, 109);
$cell-light-yellow: rgba(248, 218, 109, 0.3);
$cell-blue: rgb(143, 191, 255);
$cell-light-blue: rgb(143, 191, 255, 0.3);
$cell-purple: rgb(164, 152, 221);
$cell-light-purple: rgb(164, 152, 221, 0.3);

.skin-friendly-table {
  width: 99vw;
  &-cell {
    padding: 0.7em 0;
    text-align: center;
  }
  &-header {
    display: grid;
    grid-template-columns: 4fr 3fr 3fr 3fr 2fr;
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    .cell {
      &-grey {
        background-color: $cell-grey;
      }
      &-yellow {
        background-color: $cell-yellow;
      }
      &-blue {
        background-color: $cell-blue;
      }
      &-purple {
        background-color: $cell-purple;
      }
    }
  }
  &-sub-header {
    img {
      height: 1.8em;
      width: auto;
    }
  }
  &-sub-header,
  &-content {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr;
    text-align: center;
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }
    .skin-friendly-table-cell {
      border-bottom: 1px solid rgba(161, 173, 181, 0.3);
      font-size: 14px;
    }
    .cell {
      &-light {
        &-grey {
          background-color: $cell-light-grey;
        }
        &-yellow {
          background-color: $cell-light-yellow;
        }
        &-blue {
          background-color: $cell-light-blue;
        }
        &-purple {
          background-color: $cell-light-purple;
        }
      }
    }
  }
}
