.detailCardWidget {
  &-title {
    color: #343434;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
  }

  &-text {
    color: $gray-700;
    font-size: 12px;
  }
}
