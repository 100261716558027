.awards-cards-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  max-height: fit-content;
  //to be aligned with material ui sm value
  @media (width<600px) {
    grid-template-columns: 1fr;
  }
}
.awards-cards-content {
  background: white;
  border: 1px solid #e4e4e4;
  font-weight: 500;
  color: $dark-text;
  padding: 0;
  cursor: pointer;
  transition: all 0.1s;
  &-selected {
    background: var(--falcon-primary-light);
    border: 1px solid var(--falcon-primary);
  }

  &-img {
    object-fit: cover;
    width: 100%;
    height: 180px;
  }
  // &:hover,
  &.selected-card {
    //   cursor: pointer;
    //   transform: scale(1.1);
    background: var(--falcon-primary);
    color: white;
  }
}
.categories-wrapper {
  padding-bottom: 4px;
}
//to be aligned with material ui sm value
@media screen and (max-width: 600px) {
  .categories-wrapper {
    width: 100%;
  }

  .react-horizontal-scrolling-menu--arrow {
    &-right,
    &-left {
      display: none;
    }
  }
}

.react-horizontal-scrolling-menu--scroll-container {
  gap: 0.5rem;
  margin: 0;
  margin-top: 0.2rem;
  // -ms-overflow-style: none; /* Internet Explorer 10+ */
  // scrollbar-width: none;
  .react-horizontal-scrolling-menu--item {
    div {
      margin-bottom: 0.8rem !important;
    }
  }
  &::-webkit-scrollbar {
    // display: none; /* Safari and Chrome */
    height: 8px;
    &:hover {
      cursor: grab;
    }
  }
  &::-webkit-scrollbar-thumb {
    background: #d7dde6;

    border-radius: 10px;
    // border-radius: 10px;
  }
}
