@mixin minWidthClassGenerator($width) {
  min-width: $width;
}
@mixin maxWidthClassGenerator($width) {
  max-width: $width;
}

// (e.g., 'min-w-130' will set min-width to 130px)
$minWidthValues: 100, 120, 130, 150, 200, 300, 400, 500, 600, 700, 800;
$maxWidthValues: 200, 250, 1600, 2000;
@each $value in $minWidthValues {
  .min-w-#{$value} {
    @include minWidthClassGenerator(#{$value}px);
  }
}
@each $value in $maxWidthValues {
  .max-w-#{$value} {
    @include maxWidthClassGenerator(#{$value}px);
  }
}
