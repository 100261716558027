.segment-selection-button {
  display: flex;
  flex: 1 1 0px;
  max-width: 200px;
  min-height: 200px;
  padding: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  user-select: none;
  cursor: pointer;

  border: 2px solid #858585;
  transition: all 0.2s;

  & * {
    transition: color 0.1s;
  }

  &:hover {
    background: #feede3;
    border-color: var(--falcon-primary);

    .segment-selection-button--text {
      color: var(--falcon-primary);
    }
    .segment-selection-button--icon {
      svg {
        color: var(--falcon-primary);
      }
    }
  }

  &:active {
    .segment-selection-button--text {
      color: var(--falcon-primary-hover);
    }
    .segment-selection-button--icon {
      svg {
        color: var(--falcon-primary-hover);
      }
    }
  }

  &--icon {
    height: 90px;
    max-width: 70px;
    display: flex;
    align-items: flex-end;

    svg {
      color: #858585;
      width: 100%;
      height: 100%;
    }
  }

  &--text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3.5px;
    align-self: stretch;
    color: #858585;

    &--title {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      line-height: normal;
    }

    &--description {
      text-align: center;
      font-size: 10px;
      font-weight: 400;
      line-height: normal;
    }
  }
}
