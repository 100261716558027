.homepage {
  &-section-white {
    background-color: white;
    padding: 5rem 0 6rem;
    margin: 0 -16px;
  }
  &-section-gray {
    background-color: #f2f2f2;
    padding: 5rem 0 6rem;
    margin: 0 -16px;
  }
  &-section-inner-wrapper {
    max-width: 1200px;
    margin: auto;
  }

  .text-link {
    color: var(--falcon-primary);
    svg {
      color: var(--falcon-primary);
    }
  }
  &-landing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 190px);
  }
  &-beneficts {
    &-content {
      ul {
        li {
          list-style: none;
          display: grid;
          grid-template-columns: 30px 1fr;
          gap: 1em;
        }
      }
    }
  }
}
.beneficts-content-wrapper {
  display: flex;
  justify-content: center;
  @media (width<$mobileWidth) {
    flex-direction: column;
    align-items: center;
  }

  .beneficts-section {
    min-width: 265px;
    width: 50%;
    @media (width<$mobileWidth) {
      width: 100%;
    }
    display: flex;
    justify-content: center;
    padding: 5px 20px;
    h2 {
      font-size: 21px;
      font-weight: 400;
      margin: 0 0 17px;
    }
    ul {
      li {
        padding: 1em 0;
      }
    }
    &-middle {
      flex-basis: 80px;
      display: flex;
      justify-content: center;
      flex-shrink: 0;
      .center-line {
        background: #d9d9d9;
        width: 7px;
        padding: 110px 0 0;
        align-items: center;
        display: flex;
        flex-direction: column;
        @media (width<$mobileWidth) {
          display: none;
        }
      }
    }
  }
}

.testimonial-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 5rem;
  .testimonial {
    background: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    align-items: center;
    max-width: 100%;
    width: 480px;
    // height: 500px;
    border: 1px solid #d9d9d9;
    margin: 0 8px 40px;
    padding: 10px 25px 25px;
    font-size: 22px;

    .logo-company {
      float: left;
      font-size: inherit;
      line-height: 50px;
      padding: 2.2rem 0;
      img {
        height: 65px;
        max-width: 100%;
        margin: 20px 0;
      }
    }

    .testimonial-text {
      padding: 0;
      margin: 0;
      color: #1a535c;
    }
    .person-name {
      font-size: 24px;
      font-weight: 500;
    }
    .person-role {
      font-size: 19px;
      padding: 1rem 0 3rem;
      text-align: center;
    }
    .quote {
      font-size: 18px;
      font-weight: 300;
      text-align: center;
    }
  }
}
