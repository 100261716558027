.search-result-list {
  border: 1px solid $border-color;
  display: flex;
  min-height: 0;
  flex-grow: 1;
  flex-direction: column;

  width: 100%;
  background-color: #fff;
  position: relative;

  &-scrollbar {
    max-height: 69vh;
  }

  &-spinner {
    margin: 50px auto !important;
  }

  &-group-item {
    cursor: pointer;
    border: none !important;
    &:hover {
      background-color: rgba(64, 128, 128, 0.1);
    }

    &-selected {
      background-color: rgba(64, 128, 128, 0.1) !important;
    }

    &-compare {
      &-active {
        background-color: #e9e9e9 !important;

        &:hover {
          .row-selector {
            background-color: var(--falcon-primary);

            & > svg {
              color: #fff;
            }
          }
        }
      }

      &-selected {
        background-color: #d0d0d0 !important;
      }
    }
  }

  &-item-article {
    font-family: var(--falcon-body-font-family);
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    margin: 0;
    padding: 0;
    height: 52px;
    width: 100%;
    color: #343434;

    &::after {
      content: '';
      background: rgba(162, 174, 182, 0.3);
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
    }
  }

  &-item-row {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 13px 5px 13px 15.5px;
    overflow: hidden;
  }

  &-name {
    font-weight: 500;
  }

  &-info {
    word-break: break-all;
    padding: 0;
  }

  &-company {
    &-container {
      display: flex;
    }

    &-name {
      color: #343434;
      text-decoration: none;

      &:focus,
      &:active,
      &:hover {
        color: var(--falcon-primary);
        text-decoration: none;

        .icon-arrow-link {
          background: url('https://covalo.com/static/media/arrow_link_coral.cf216271.svg')
            center no-repeat !important;
        }
      }
    }
  }

  &-floating-button-group {
    position: absolute;
    height: 0;
    bottom: 75px;
    left: 58%;
    transform: translateX(-50%);
    cursor: pointer;
    width: 100%;
    z-index: 99;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
  }
}
