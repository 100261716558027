.header-info-block-wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 1em;
  position: relative;
  margin-top: 16px;
  padding: 12px 24px;
}
.company-header-divider {
  height: 100%;
  width: 1px;
  background-color: #c5c5c5;
  margin-right: 1.5em;
  @media (width<$mobileWidth) {
    margin: 1.5rem 0;
    height: 1px;
    width: 100%;
  }
}

.company-page-header-img {
  position: relative;

  width: 100%;
  max-width: 1600px;
  height: auto;
  aspect-ratio: 5/1;
}

.page-company-logo-image {
  width: auto;
  height: 120px;
  border: 1px solid #c9c9ca;
  border-radius: 10px;
  padding: 0.5em;
  background-color: white;
  @media (width<$mobileWidth) {
    height: 80px;
  }
}
.seg,
.segment-section-image {
  position: relative;
  flex: 1;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    @media (width<$mobileWidth) {
      position: static;
    }
  }
}

.company-header-image {
  object-fit: contain;
  max-width: 150px;
  height: 100px;
  border: 1px solid #c5c5c5;
  background-color: white;
  z-index: 3;
  padding: 10px;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.company-segment-section {
  padding: 0 16px;
  display: grid;
  text-overflow: ellipsis;
  overflow: hidden;
  grid-template-columns: repeat(5, 19%);
  gap: 16px;
  @media (width<900px) {
    grid-template-columns: repeat(3, 32%);
  }
  @media (width<$mobileWidth) {
    grid-template-columns: repeat(2, 49%);
  }
  @media (width<450px) {
    grid-template-columns: 1fr;
  }
}
