.product_page {
  &-sticky-header {
    position: sticky;
    z-index: 999;
    top: 0px;
    background-color: white;
    box-shadow: 0 0px 0 0 #ffffff;
    transition: all 0.3s ease;
    h1,
    h5,
    .multi-products-actions-wrapper {
      @media (width<$mobileWidth) {
        display: grid;
        grid-template-columns: 1fr;
        .btn-primary {
          width: 100%;
        }
      }
      transition: all 0.3s ease;
    }
  }
  &-image_header {
    @media (width<$mobileWidth) {
      display: none;
    }
    padding: 0;
    display: flex;
    gap: 10px;
    &-logo {
      flex: 1;
      display: flex;
      min-width: 16%;
      background-color: white;
      padding: 8px;

      &-img {
        margin: auto;
        width: 100%;
        // max-height: 200px;

        @media (width>=1600px) {
          padding: 16px;
        }
      }
    }
  }
  &-header {
    &-company {
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      gap: 5px;
      h4 {
        font-weight: 400;
        font-size: 18px;
        text-decoration: underline;
        margin: 0;
      }
    }
    &-product {
      display: flex;
      align-items: center;
    }
  }
  &-details-wrapper .separator_line:last-of-type {
    display: none;
  }
}

.product-card-company-logo {
  height: 50px;
  width: 50%;
  padding: 0 4px;
  margin-right: 10px;
}

.product-card-company-banner {
  height: 70px;
  object-fit: cover;
  width: -webkit-fill-available;
  @media (width<1180px) {
    height: 120px;
  }
  @media (width<768px) {
    height: 90px;
    width: 100%;
  }
  @media (width<577px) {
    height: 90px;
    width: calc(100vw - 5.5em);
  }
}

.product_page-documents {
  &-empty {
    display: flex;
    min-height: 371px;
    gap: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #858585;
    &-title {
      font-family: Roboto;
      font-size: 32px;
      font-weight: 400;
      line-height: 38px;
      letter-spacing: 0px;
    }
    &-subtitle {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
    }
    &-icon {
      margin-top: 5px;
      svg {
      }
      svg:hover {
        color: white;
      }
    }
  }
}

.contacts-table-filters {
  .stickyOnScroll {
    box-shadow: none !important;
    border-right: 1px solid #e2e2e2 !important;
  }
}
