.search-bar {
  div.react-autosuggest__container {
    flex: 1 1 auto;
    min-width: 0;
    position: relative;
    width: 1%;

    .react-autosuggest__suggestions-container--open {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
      -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
      -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
      width: 100%;
      position: absolute;

      margin-top: 0.3rem;
      padding: 5px 0;
      border: none;
      background-color: white;
      z-index: 1;

      ul.react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        text-align: left;

        li.react-autosuggest__suggestion {
          cursor: pointer;
          padding: 0.7rem 1.3rem;
          color: #343434;
          font-size: 14px;
          line-height: 1.42857143;
        }

        li.react-autosuggest__suggestion--highlighted {
          background: rgb(224, 224, 224) none;
        }
      }
    }

    input.react-autosuggest__input {
      background: none;
      border-bottom: 1px solid #1a535c;
    }
  }
}

.search-history-results {
  max-height: calc(100vh - 315px);
  position: absolute;
  top: 40px;

  right: -51px;
  width: 330px;
  overflow-y: scroll;
  background-color: white;
  z-index: 99;
}

.history-item:hover {
  background-color: var(-falcon-primary-light);
  cursor: pointer;
}
