.mobile-nav-bar-menu {
  background-color: #fff;
  padding: 0px 12px 16px;
  font-size: 14px;
  text-align: left;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 5px rgba(0, 0, 0, 0.075);
}

.mobile-nav-bar-top-left {
  position: absolute;
  left: 11px;
  top: 81px;
}

.mobile-nav-dropdown {
  .dropdown {
    .dropdown-menu-card {
      border: none;
      box-shadow: none;
      margin-left: 15px;
    }
  }
}
