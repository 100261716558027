.filterdropdown-root {
  .filterdropdown-head {
    min-width: 160px;
    border-bottom: 1px solid var(--falcon-primary);
    height: 24px;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex: 1;
    align-items: baseline;

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }

    &-label {
      font-size: 14px;
      line-height: 14px;
      color: #343434;

      &-count {
        display: flex;
        height: 14px;
        padding: 2px 4px;
        align-items: center;
        background: #feede3;
        color: var(--falcon-primary);
        font-size: 12px;
        font-weight: 700;
      }
    }

    svg {
      color: #343434;
      &:hover {
        color: #343434;
      }
    }
  }

  .filterdropdown-popper {
    min-width: 160px;
    max-width: 350px;
    max-height: 65vh;
    overflow: auto;
    position: absolute;
    background: white;
    box-shadow: $dropdown-box-shadow;
    z-index: 1;

    &-head {
      user-select: none;
      display: flex;
      flex-direction: row;
      justify-content: end;
      padding: 5px;
      border-bottom: 1px solid var(--falcon-border-color);
    }

    &-clearall {
      font-size: 12px;
      padding: 0;
    }

    .filterdropdown-groups {
      padding: 0;
      margin: 0;

      > li {
        list-style: none;
        border-bottom: 1px solid var(--falcon-border-color);

        &:last-child {
          border-bottom: none;
        }
      }

      &-single {
        padding-top: 8px;
      }
    }
  }
}
