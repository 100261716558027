.search-col-position {
  position: relative;
  margin-top: -16px;
}
.search {
  &-compare-btn {
    background-color: #fff !important;
    color: var(--falcon-primary) !important;
    border: 1px solid var(--falcon-primary) !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    font-weight: 400 !important;
    padding: 8px !important;
    svg {
      color: var(--falcon-primary) !important;
    }
    &.float-right {
      float: right;
    }
    margin-top: 0px;
    &:hover,
    &-active {
      background-color: var(--falcon-primary) !important;
      color: #fff !important;
      svg {
        color: #fff !important;
      }
    }
    &:disabled {
      opacity: 0.5;
      color: var(--falcon-btn-disabled-color) !important;
      border-color: var(--falcon-btn-disabled-color) !important;
      svg {
        color: var(--falcon-btn-disabled-color) !important;
      }
    }
  }

  &-remove-scroll {
    overflow: hidden !important;
    @media screen and (max-width: 768px) {
      overflow: auto !important;
    }
  }
}
